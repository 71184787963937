<template>
  <li id="0" :class="{ belowSelected: selectedTab - 1 == 0 }">
    <div
      :class="{ tab: true, specialTab1: selectedTab - 1 == 0 }"
      id="invisible-1"
    >
      <h2>&nbsp;</h2>
    </div>
  </li>
  <li
    id="1"
    @click="selectTab(1)"
    :class="{
      chosenTab: selectedTab == 1,
      aboveSelected: selectedTab - 1 == 1,
      belowSelected: selectedTab + 1 == 1,
    }"
  >
    <div
      :class="{
        tab: true,
        specialTab1: selectedTab - 1 == 1,
        specialTab2: selectedTab + 1 == 1,
      }"
      id="default"
    >
      <div>
        <ion-icon name="home-outline" class="small-icon"></ion-icon>
      </div>
      <h2 v-show="!isCollapsed" id="dashboard">Home</h2>
    </div>
  </li>
  <li
    id="2"
    @click="selectTab(2)"
    :class="{
      chosenTab: selectedTab == 2,
      aboveSelected: selectedTab - 1 == 2,
      belowSelected: selectedTab + 1 == 2,
    }"
  >
    <div
      :class="{
        tab: true,
        specialTab1: selectedTab - 1 == 2,
        specialTab2: selectedTab + 1 == 2,
      }"
    >
      <div>
        <ion-icon name="sparkles-outline" class="small-icon"></ion-icon>
      </div>
      <h2 v-show="!isCollapsed">WishList</h2>
    </div>
  </li>
  <li
    id="3"
    @click="selectTab(3)"
    v-show="!localSeniorStatus"
    :class="{
      chosenTab: selectedTab == 3,
      aboveSelected: selectedTab - 1 == 3,
      belowSelected: selectedTab + 1 == 3,
    }"
  >
    <div
      :class="{
        tab: true,
        specialTab1: selectedTab - 1 == 3,
        specialTab2: selectedTab + 1 == 3,
      }"
    >
      <div>
        <ion-icon name="briefcase-outline" class="small-icon"></ion-icon>
      </div>
      <h2 v-show="!isCollapsed" id="opp-decision">Opportunities</h2>
    </div>
  </li>
  <li
    id="4"
    @click="selectTab(4)"
    :class="{
      chosenTab: selectedTab == 4,
      aboveSelected: selectedTab - 1 == 4,
      belowSelected: selectedTab + 1 == 4,
    }"
  >
    <div
      :class="{ tab: true, specialTab2: selectedTab + 1 == 4 }"
      id="message-label"
    >
      <p v-if="isCollapsed&&unreadMsgs>0" class="unreadMsgs-small">{{unreadMsgs}}</p>
      <div>
        <ion-icon name="chatbox-ellipses-outline" class="small-icon"></ion-icon>
      </div>
      <h2 v-show="!isCollapsed">Messages<p v-if="unreadMsgs>0" class="unreadMsgs">{{unreadMsgs}}</p></h2>
    </div>
  </li>
  <li id="5" :class="{ belowSelected: selectedTab + 1 == 5 }">
    <div
      :class="{ tab: true, specialTab2: selectedTab + 1 == 5 }"
      id="invisible-2"
    >
      <h2>&nbsp;</h2>
    </div>
  </li>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  props: ["isCollapsed", "selectedTab", "onSeniorEnd", "unreadMsgs"],
  emits: ["selected-tab-change"],
  setup(props, { emit }) {
    const localSeniorStatus = ref(props.onSeniorEnd);
    const router = useRouter();

    const selectTab = (id) => {
      //props.selectedTab = id;
      emit("selected-tab-change", id);
      switch (id) {
        case 1:
          router.push({ name: "Junior-Home" });
          break;
        case 2:
          router.push({ name: "Junior-WishList" });
          break;
        case 3:
          router.push({ name: "Junior-Opportunities" });
          break;
        case 4:
          router.push({ name: "Junior-Messages" });
          break;
      }
    };

    return { localSeniorStatus, selectTab };
  },
};
</script>

<style scoped>
.tab {
  height: 7.3vh;
  display: flex;
  padding: 1.5vh 0;
  padding-left: 30px;
  align-items: center;
}

.tab:hover {
  color: purple;
  cursor: pointer;
}

.tab div {
  display: flex;
  align-items: center;
  flex: 0 0 60px;
  padding-right: 20px;
  transition: color 0.25s ease;
}

.tab h2 {
  font-size: var(--subheader);
  flex: 1 0 auto;
  font-family: Open Sans;
  font-weight: bold;
  padding: auto 0;
  transition: color 0.25s ease;
}

.small-icon {
  font-size: 150%;
  margin-right: 5%;
  margin-left: 10%;
}

.chosenTab {
  font-family: Open Sans;
  font-style: normal;
  background-color: var(--background);
  color: var(--purple);
}

.aboveSelected {
  background-color: var(--background);
}

.belowSelected {
  background-color: var(--background);
}

@media screen and (max-width: 600px){
  .chosenTab {
    background-color: var(--background);
  }

  .aboveSelected {
    background-color: var(--background);
  }

  .belowSelected {
    background-color: var(--background);
  }
}

.specialTab1 {
  background: linear-gradient(270deg, #4D068C 0%, #0A0337 188.33%);
  border-radius: 0px 0px 40px 0px;
}

.specialTab2 {
  background: linear-gradient(270deg, #4D068C 0%, #0A0337 188.33%);
  border-radius: 0px 40px 0px 0px;
}

.unreadMsgs {
  font-size: 80%;
  padding: 0.5vh 1vw;
  margin-left: 3vw;
  display: inline;
  color: #351A7D;
  background-color: #F1A540;
  border-radius: 1.5vh;
}

#message-label {
  position: relative;
}

.unreadMsgs-small {
  position:absolute;
  top: 0.8vh;
  left: 4.6vw;
  font-size: 70%;
  padding: 0.3vh 0.5vw;
  display: inline;
  color: #351A7D;
  background-color: #F1A540;
  border-radius: 1.5vh;
}
</style>
